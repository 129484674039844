#div-for-modal {
    .modal {
        z-index: 10010;
    }
}

/* modals */
#auth-modal {
  z-index: 10002;
  .modal-body {
    padding: 0 !important;
  }
}
#auth-body {
  background-color: #fff;

  button.close-modal {
    position: absolute;
    top: -40px;
    right: 0;

    span {
      color: #fff;
      opacity: 1 !important;
      text-shadow: none;
      font-size: 32px;
      font-weight: 700;
    }
  }
  .modal-container {
    padding: 50px 100px 30px;
  }

  h3.main-header {
    background-color: #ff0000;
    padding: 20px 0;
    color: #fff;
    text-align: center;
  }

  form  {
    label {
      text-transform: uppercase;
      width: 100%;

      span a {
        color: #ff0000;
        float: right;
      }
    }

    .submit-button {
      background-color: #ff0000;
      border: 0;
      padding: 10px 50px;
      color: #fff;
      font-size: 20px;
    }
  }

  .create-button {
    background-color: #ccc;
    border: 0;
    padding: 10px 50px;
    color: #fff;
    font-size: 20px;
    text-decoration: none;
  }

  h3.line-through {
    width: 100%;
    text-align: center;
    border-bottom: 1px solid #ccc;
    line-height: 0.1em;
    margin: 10px 0 20px;
  }

  h3.line-through span {
    background:#fff;
    padding:0 10px;
  }
}

#menuset-parts {
    li {
      position: relative;

      span {
        position: absolute;
        bottom: 0;
        left: 0;
        text-align: center;
        width: 100%;
        height: 20px;
        line-height: 20px;
        padding: 0 28px 0 8px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
}
