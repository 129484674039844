// main button
.btn-style {
  background-color: #e3001b;
  color: #ffffff;
  font-size: 14px;
  line-height: inherit;
  display: inline-block;
  border: 0;
  font-weight: bold;
}

// widths
.btn-w-140 {
  min-width: 140px;
}

// button red
.btn-red {
  background-color: $color_red;
  border-color: darken($color_red, 10%);
  color: #fff;
  font-weight: 700;

  &:hover {
    color: #fff;
    background-color: darken($color_red, 10%);
  }
}

.btn-red-round {
  background-color: $color_red;
  border-color: darken($color_red, 10%);
  color: #fff;
  font-weight: 700;
  border-radius: 4px;

  &:hover {
    color: #fff;
    background-color: darken($color_red, 10%);
  }
}

// button green
.btn-green {
  background-color: $color_green;
  border-color: darken($color_green, 10%);
  color: #fff;
  font-weight: bold;

  &:hover {
    color: #fff;
    background-color: darken($color_green, 10%);
  }
}

.btn-green-round {
  background-color: $color_green;
  border-color: darken($color_green, 10%);
  color: #fff;
  font-weight: 700;
  border-radius: 4px;

  &:hover {
    color: #fff;
    background-color: darken($color_green, 10%);
  }
}

// helpers
[class*="btn"] {
  white-space: nowrap;
  padding: 15px 20px;

  &[class*="round"] {
    padding: 10px 20px;
  }
}

[class*="btn"] {
  + & {
    margin-left: 10px;
  }
}


