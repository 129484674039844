/* base rules */
.ico-menu {
  width: 100%;
  height: 100%;
  display: inline-block;
  vertical-align: middle;
  border: 0;
  padding: 0;
  margin: 0;

  background-position: center;
  background-repeat: no-repeat;
  background-color: transparent;
}
.ico-beef {
  background: url($path-to-img + "menu/beef.png") center no-repeat;
}

.ico-chicken {
  background: url($path-to-img + "menu/chicken.png") center no-repeat;
}

.ico-mozzarella {
  background: url($path-to-img + "menu/mozzarella.png") center no-repeat;
}

.ico-onions {
  background: url($path-to-img + "menu/onions.png") center no-repeat;
}

.ico-olive {
  background: url($path-to-img + "menu/olive.png") center no-repeat;
}

.ico-ingredient {
  background: url($path-to-img + "menu/ingredient.png") center no-repeat;
}