.pagination {

  li {
    a {
      margin: 0 3px;
      color:#fff;
      background-color: $color_middle_grey;
      box-shadow: inset 0 0 5px #666;
      border-radius: 3px;
      height: 40px;
      line-height: 40px;
      min-width: 30px;
      padding: 0 10px;
      border: none;
      display: inline-block;

      span {
        font-size: 12px;
      }
    }

    &.active, &:hover {
      a {
        background-color: $color_red;
        box-shadow: none;
        color: #fff;
      }
    }
  }

  .prev-page {
    a {
      margin-right: 10px;
    }
  }

  .next-page {
    a {
      margin-left: 10px;
    }
  }

  .prev-page, .next-page {

    a {
      @media(max-width: $screen-xs-max){
        font-size: 0;
      }
    }

    .fa {
      vertical-align: middle;
    }
  }
}

.custom-pagination li a {
  border-radius: 0 !important;
  box-shadow: none !important;
}