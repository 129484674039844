
.title-type1, .title-1 {
  position: relative;
  text-decoration: none;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  display: block;
  font-family: Verdana, Arial, "Bitstream Vera Sans", sans-serif;
  color: #3c3e3d;
  margin: 60px 0 60px 0;



  &.white {
    color: #ffffff;

    &:after {
      background: #ffffff;
    }

    &:before {
      background: #ffffff;
    }
  }

  &.no-margin {
    margin: 0;
  }
}
