// buttons
.tab-button-2 {

  .tab-button {
    background: #f0f0f0;
    text-align: center;
    display: block;
    height: 60px;
    line-height: 65px;
    font-weight: bold;
    color: #464646;
    margin-right: 20px;
    margin-bottom: 1px;
  }

  .active {

    .tab-button {
      color: #e2001b;
      position: relative;

      &:after {
        content: '';
        width: 6px;
        height: 100%;
        background: #e2001b;
        position: absolute;
        top: 0;
        right: 0;
      }
    }
  }
}
