
.title-type5, .title-5 {
  position: relative;
  text-decoration: none;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  display: block;
  font-family: Verdana, Arial, "Bitstream Vera Sans", sans-serif;
  color: #3c3e3d;
  margin: 60px 0 60px 0;

  &:after {
    content: '';
    width: 100%;
    display: block;
    margin: 15px auto;
    height: 12px;
    background-repeat: repeat-x;
    background-image: url('/imgs/common/line-segment.png');
  }

  &.white {
    color: #ffffff;

    &:after {
      background: #ffffff;
    }

    &:before {
      background: #ffffff;
    }
  }

  &.no-margin {
    margin: 0;
  }
}
