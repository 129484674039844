

/* base rules */
.ico {
  width: 25px;
  height: 25px;
  display: inline-block;
  vertical-align: middle;
  border: 0;
  padding: 0;
  margin: 0;

  background-position: center;
  background-repeat: no-repeat;
  background-color: transparent;
}

.ico-white {
    color: white;
}

.ico-bg-grey {
  border-radius: 3px;
  line-height: 30px;
  text-align: center;
  background-color: #3e3e3e;
}

.ico-bg-red {
  border-radius: 3px;
  line-height: 30px;
  text-align: center;
  background-color: #e3001e;
}

.ico-circle-red {
  width: 18px;
  height: 18px;
  vertical-align: middle;
  line-height: 18px;
  color: #fff;
  font-size: 12px;
  border-radius: 50%;
  text-align: center;
  background-color: #e3001e;
}

/* catch  */
.ico {
  .fa-facebook {
    vertical-align: middle;
  }
  .fa-facebook {
    vertical-align: middle;
  }
}

/* helpers */
.ico-margin-left {
  margin-left: 20px;
}

.ico-space-separator {
  width: 0;
  display: inline-block;
  height: 1px;
}

.ico-group  {
  display: inline-block;
}

/* list icons */

// (k) casher
.ico-k {
  background-image: url($path-to-img + 'icon-k.png');
}

// (m) halal
.ico-m {
  background-image: url($path-to-img + 'icon-m.png');
}

// (b) bio
.ico-b {
  background-image: url($path-to-img + 'icon-b.png');
}

// (v) vegetarian
.ico-v {
  background-image: url($path-to-img + 'icon-v.png');
}

// (cal)
.ico-cal {
  width: 20px;
  height: 20px;
  background-image: url($path-to-img + 'icon-calendar.png');
}

// cart
.ico-cart {
  width: 15px;
  height: 25px;
  background-image: url($path-to-img + 'icon-cart.png');
}

// door, popop
.ico-door {
  width: 15px;
  height: 25px;
  margin-top: -4px;
  background-image: url($path-to-img + 'icon-door.png');
}

// Carryout
.ico-carryout {
    width: 78px;
    height: 78px;
    background-image: url($path-to-img + 'Carryout.png');
}
button:hover .ico-carryout ,.ico-carryout:hover {
    background-image: url($path-to-img + 'Carryout-dark.png');
}


// Delivery
.ico-delivery {
    width: 78px;
    height: 78px;
    background-image: url($path-to-img + 'Delivery.png');
}
button:hover .ico-delivery, .ico-delivery:hover {
    background-image: url($path-to-img + 'Delivery-dark.png');
}


.ico-delivery.black-ico {
    width: 27px;
    height: 27px;
    background-image: url($path-to-img + 'Delivery-black.png');
}

// social
.ico-social {
  height: 22px;
  width: 22px;
  line-height: 22px;
  text-align: center;

  .fa {
    font-size: 18px;
    display: inline;
    vertical-align: inherit;
    color: #fff;
  }

  &.ico-fb {
    margin-right: 3px;

    .fa {
      position: relative;
      top: 3px;
    }
  }
}

.ico-time {
  background-image: url("../../imgs/common/time.png");
  width: 16px;
  height: 18px;
  margin-right: 10px;
}

.ico-visa {
  background-image: url("../../imgs/common/credit-cards.png");
  width: 44px;
  height: 26px;
  display: inline-block;
  background-position: 0px;
}

