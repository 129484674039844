$path-to-img: '../img/';
$icon-font-path: "../../fonts/bootstrap/" !default;

// plugins
@import 'bootstrap';
@import "fontawesome/font-awesome";
@import "partials/animate";

// settings ex., colors
@import "settings";

// mains files
@import "partials/reset";
@import "partials/gap";
@import "partials/container";
@import "partials/fonts";
@import "partials/icons";
@import "partials/icons_menu";
@import "partials/main-header";
@import "partials/background";
@import "partials/news";
@import "partials/reviews";
@import "partials/newsletter";
@import "partials/paginate";
@import "partials/input";
@import "partials/footer";
@import "partials/cart";
@import "partials/pop-over";
@import "partials/modal/modal";
@import "partials/forms";
@import "partials/map";
@import "partials/text";
@import "partials/tab/tab";
@import "partials/breadcrumbs-menu";
@import "partials/bg-food";
@import "partials/comment";
@import "partials/logo";
@import "partials/datepicker";
@import "partials/timepicker";

//inputs
@import "partials/dish/dish";

// pages
@import "pages/home";
@import "pages/menu";
@import "pages/contact";
@import "pages/information";
@import "pages/single_post";
@import "pages/news_page";
@import "pages/basket";

@import 'partials/butons';

// titles
@import 'partials/title/title';

// menu
@import "partials/menu/menu";

@import "partials/display";

// reservation form visible on all pages
@import "partials/reservation-form";
@import "partials/customScrollbar";

// menus
@import "menus/general";
@import "menus/nopadded";

// modals
@import "modals/todays";
@import "modals/order";
@import "partials/cookies";
@import "partials/legal_notice";

#reservation-table {
  -vendor-animation-duration: .3s;
  -vendor-animation-delay: 0;
}

.background-style {
  background-color: #fff;
  background-image: inherit;
  background-repeat: no-repeat;
  background-position: top center;
}

.text-style {
  font-size: 14px;
  font-family: Verdana, Arial, "Bitstream Vera Sans", sans-serif;
  color: #3d3d3d;

  a {
    color: #000080;
  }
}

p {
  color: #3d3d3d;
}

#restaurant-closed-modal {
  z-index: 10002;
}

.default-row {
    display: flex;
    align-items: center;
}

.modal-spinner {
    width:100%;
    height: 100%;
    position: absolute;
    background-color:rgba(0,0,0,0.3);
    top: 0;
    left: 0;
    z-index: 2000;
    color:white;
    text-align: center;
    padding-top: 25%;

    .glyphicon-spin {
        -webkit-animation: spin 1000ms infinite linear;
        animation: spin 1000ms infinite linear;
        font-size: 2em;
    }
    @-webkit-keyframes spin {
        0% {
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
        }
        100% {
            -webkit-transform: rotate(359deg);
            transform: rotate(359deg);
        }
    }
    @keyframes spin {
        0% {
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
        }
        100% {
            -webkit-transform: rotate(359deg);
            transform: rotate(359deg);
        }
    }
}
// FIX for EASY-778
.navbar-collapse.collapse.in {
  overflow: visible !important; 
}

#free-page-content {
  margin: 60px auto;

  p, img, h2, h3 {
    display: block;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
  strong {
    font-family: TextContentBold;
  }
}