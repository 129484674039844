.newsletter-background {

  background-position: center;

  .newsletter {

    .newsletter-title {
      color: #fff;
      font-size: 26px;
      margin: 0;
    }

    .input-text {
      font-size: 14px;
      height: 40px;
      line-height: 40px;
      padding: 0 20px;
      display: inline-block;
      float: none;
    }

    .newsletter-submit {
      font-size: 14px;
      text-transform: uppercase;
      font-weight: bold;
      height: 40px;
      line-height: 40px;
      display: inline-block;
      margin-left: 5px;
      margin-top: 30px;
      padding: 0 30px;
      position: relative;
      &:hover {
        color: #23527c !important;
      }
    }
  }

}

.newsletter-background-modal {

  .gap-80px {
    display: none;
  }
  .gap-30px {
    height: 5px;
    line-height: 5px;
  }

  .container {
    padding: 0;
  }
  

  .newsletter {
    .newsletter-title {
      font-size: 16px !important;
      text-align: left !important;
      color: #000 !important;
      font-weight: bold !important;
      padding-top: 25px;
    }

    h2 {
      padding: 0;
    }
    .newsletter-submit {
      margin-top: 0;
    }
  }  

  form {
    width: 550px;
    margin: 0 0 20px 0;

    .input-text {
      background: transparent !important;
    }
  }
}

#restaurant-closed-modal .newsletter-background::after {
  background-image: none !important
}

.modal-body-with-newsletter-1 {
  padding-bottom: 20px !important;
}

.modal-body-with-newsletter-2 {
  padding-top: 0 !important;
}