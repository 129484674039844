.pop-over {
  font-style: normal;
  width: 20px;
  height: 20px;
  position: relative;

  &:hover {
    .popover-content {
      display: block;
    }
  }

  .popover-content {
    $pop-over-width: 280px;

    display: none;
    position: absolute;
    z-index: 10;

    padding: 20px;
    box-shadow: 0 0 5px #eceded;
    width: $pop-over-width;

    &:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      background: #ffffff;
      top: 0;
      left: 0;
      z-index: -1;
    }

    &:before {
      content: '';
      width: 20px;
      height: 20px;
      position: absolute;
      z-index: -2;

      background: #ffffff;
      box-shadow: 0 0 5px #eceded;

      -ms-transform: rotate(45deg);
      -moz-transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
      -o-transform: rotate(45deg);
      transform: rotate(45deg);
    }

    &.left {
      margin-left: 20px;

      &:before {
        top: 50%;
        left: -10px;
        margin-top: -3px;
      }
    }

    &.top {
      &:before {
        left: 50%;
        top: -15px;
        margin-left: -15px;
      }
    }

    .pop-title {
      margin: 0;
      padding: 0;
      display: block;
      color: #3e4040;
      font-size: 14px;
      text-align: center;
      font-weight: bold;
      text-transform: uppercase;
    }

    .pop-item-title {
      margin: 0;
      padding: 0;
      display: block;
      color: #e20520;
      font-size: 14px;
      font-weight: bold;
    }

    .pop-item-desc {
      margin: 0;
      padding: 0;
      display: block;
      color: #868686;
      font-size: 14px;
      line-height: 24px;
      font-weight: normal;
    }

  }

}