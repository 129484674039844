.news {

  .new-item {

    .new-img {
      max-height: 150px;
    }

    .new-title {
      font-size: 18px;
      color: #3c3e3d;
      font-weight: bold;
      margin: 0 0 14px 0;
    }

    .new-meta {
      font-size: 14px;
      margin-bottom: 20px;
    }

    .new-read-more {
      text-transform: uppercase;
      font-weight: bold;
    }

  }

}