@import "menu-1";
@import "menu-2";
@import "menu-3";
@import "menu-4";
@import "menu-5";
@import "menu-6";
@import "menu-7";
@import "menu-8";
@import "menu-9";

@media (max-width: 768px) {
  .title-6 {
    display: block;
  }

  .container > .row.display-none {
    display: block;
  }
  .cart{display: none}
}

@media (max-width: 768px) {
  #ordersapp article.list-version {
    margin-top: 70px;
  }
}