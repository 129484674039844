.menu-9 {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url('/imgs/menu/composes-vorte-pizza-holder.png');
  padding:20px;
  padding-bottom: 30px;
  color:#fff;

  .title {
    font-size: 26px;
    font-weight:bold;
    width:100%;
    margin-top:10px;
    padding-bottom:20px;
    background: url('/imgs/menu/line-segment-red.png') bottom repeat-x;
  }

  .desc {
    font-size: 18px;
    margin-bottom:40px;
    margin-top:10px;
  }

  .title2 {
    font-weight:bold;
    font-size: 18px;
  }

  .cost {
    background-color: $color_green;
    border: 4px solid #fff;
    border-radius: 50%;
    text-align: center;
    font-size: 30px;
    font-weight: bold;
    width:70px;
    height: 70px;
    padding-top:10px;
    margin:30px auto 0;
  }

  .info {
    font-size: 18px;
  }
}