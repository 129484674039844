@media (min-width: 961px ) {
  .container {
    width: 960px;
  }
}

@media (max-width: 960px ) {
  .container {
    width: 100%;
  }
}