#single-post {
  color: $color_dark_grey;

  .img-responsive {
    margin:0 auto;
  }

  .title-post {
    color:$color_red;
    font-weight: bold;
  }

  #page-info {

    .fa {
      color:$color_green;
    }

    .pull-left {
      margin-right: 10px;
      margin-bottom: 10px;
    }

  }

  .line-grey {
    border-bottom: 2px solid $color_grey;
  }
  
  .fb-share-button {
    margin-left: 20px;
  }

}

