.where-do-you-live {
  
  z-index: 10120;
  .fa-map-marker {
    font-size: 22px;
    vertical-align: middle;
    margin-top: -4px;
  }

  .list {
    list-style: none;
    padding:0;
    margin:0;

    li.city-to-select {
      line-height: 24px;
      font-weight: bold;
      cursor: pointer;

      &:hover {
        color: #009136;
      }
    
    }

    li.city-to-select.active {
      // color: #009136;
    }

  }
  .modal-title {
    color:white !important;
    font-size:16px !important;
    text-align:left;
    margin-left:5px;
  }

  a.go-back-delivery:hover, a.go-back-delivery:focus {
    cursor: pointer;
  }
}


