.menu-5 {

  &.menu-item {
    position: relative;
    background: #f5f5f5;
    padding: 20px 20px 0 20px;

    .menu-img {
      // max-height: 150px;
      width: 100%;
      height: auto;
      max-height: none;
      box-shadow: 0 0 5px #eeeeee;
    }

    .menu-title {
      font-size: 24px;
      color: #e3001b;
      font-weight: bold;
      margin: 0 0 14px 0;
    }

    .menu-variant-list {
      list-style: none;
      padding: 0;
      display: block;
      margin: 0;

      li {
        color: #878787;
        font-weight: bold;
        line-height: 30px;
        display: block;

        padding: 0;
        overflow-x: hidden;

        &:before {
          float: left;
          width: 0;
          white-space: nowrap;
          content:
                  ". . . . . . . . . . . . . . . . . . . . "
                  ". . . . . . . . . . . . . . . . . . . . "
                  ". . . . . . . . . . . . . . . . . . . . "
                  ". . . . . . . . . . . . . . . . . . . . ";
        }

        .menu-variant-text {
          background: #f5f5f5;
          padding-right: 5px;

          @media (max-width: 768px) {

            font-weight: normal;
            font-size: 18px;
          }
        }

        .menu-variant-price {
          background: #f5f5f5;
          padding-left: 5px;
          color: #1b9848;

          @media (max-width: 768px) {
            font-size: 18px;
          }
        }

      }
    }

    .menu-text {
      color: #7e7e7e;
    }

  }

}