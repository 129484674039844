.menu-7 {

  &.menu-item {
    position: relative;
    background: #f5f5f5;
    padding: 20px 20px 0 20px;

    .menu-img {
      // max-height: 150px;
      width: 100%;
      height: auto;
      max-height: none;
      box-shadow: 0 0 5px #eeeeee;
    }

    .menu-title {
      font-size: 24px;
      color: #e3001b;
      font-weight: bold;
      margin: 0 0 14px 0;
    }

    .button-style {
      &~.menu-price {
        line-height: 49px;
      }
    }

    .menu-price {
      color: #24994a;
      font-weight: bold;
      font-size: 18px;
      line-height: 18px;
      vertical-align: middle;
      display: inline-block;

      @media (max-width: 768px) {
        font-size: 34px;
      }

    }

    .menu-text {
      color: #7e7e7e;

      @media (max-width: 768px) {
        margin-top:10px;
      }
    }

  }

}