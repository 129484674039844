#main-header.information {
  .navbar-top {
    background-color: #2A2C2B;
  }
}
#navbar-top-information {
  background-color: #2A2C2B;
  .links {
    li {
      float: left;
      a {
        border-right: 1px solid $color_dark_grey;
        font-weight: bold;
        padding: 5px 10px;
      }
      &:first-child {
        a {
          padding-left: 0;
        }
      }
    }
  }
  .ico {
    color: #fff;
  }
}

.information-phone {
  width: 65% !important;
  background-color: #33A744;
  border-radius: 50px;
  color: #fff;
  text-align: center;
  h3 {
    padding: 5px 0;
    font-weight: 700;
    i {
      font-size: 22px;
      margin-top: 3px;
    }
    small {
      color: #fff;
    }
  }
}
.information-ferme {
  width: 35% !important;
  color: $color_red;
  text-align: right;
  padding-top: 5px;
  h3 {
    font-weight: 700;
  }
  i {
    font-size: 25px;
  }
}

.navbar.information {
  .nav {
    li {
      a {
        font-weight: 300 !important;
        padding-left: 3px !important;
        &:after {
          content: "/";
          color: $color_green;
          margin-left: 20px;
        }
      }
      &:last-child {
        a:after {
          content: '';
          margin-left: 0;
        }
        a {
          padding-right: 0 !important;
        }
      }
    }
  }
}

#information-bg {
  padding: 30px 0;
}
#information {
  background-color: #fff;
  border: 1px solid $color_grey;
  padding: 30px;
  h3 {
    font-family: Verdana, Arial, "Bitstream Vera Sans", sans-serif;
    padding-bottom: 15px;
    i {
      font-size: 22px;
    }
  }
  h4 {
    font-family: Verdana, Arial, "Bitstream Vera Sans", sans-serif;
    color: $color_red;
    margin-bottom: 15px;
  }
  h4.color-grey {
    color: #333 !important;
  }
  ul {
    li {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      span {
        color: $color_green
      }
    }
  }
  .payment-details {
    li, span {
      font-size: 12px;
      font-weight: bold;
    }
  }
  .comfort-details {
    li, i, span {
      font-weight: bold;
      font-size: 12px;
    }
    li span {
      color: $color_dark_grey !important;
    }
  }
}

.horizontal-line {
  height: 2px;
  background-color: darken($color_grey, 6%);
  margin-bottom: 15px;
}


.align-vertical {
  display: flex;
  align-items: center;
}
.color-red {
  color: $color_red !important;
}

.color-green {
  color: $color_green !important;
}

.livraison {
  float: right;
  li {
    background-color: $color_grey;
    border-radius: 5px;
    float: left;
    padding: 10px;
    margin-right: 5px;
    span {
      margin-left: 5px;
    }
  }
}
.font-12 {
  font-size: 12px;
}

.font-14 {
  font-size: 14px;
}

.font-bold {
  font-weight: bold;
}
.ico-social.ico-fb .fa {
  top: 0;
}

.information#main-header .menu-style {
  margin-top: 23px;
}

.information {
  .navbar {
    min-height: 0;
    margin-bottom: 0;
  }
  .navbar-nav {
    float: right;
    margin: 0;
  }
  .navbar-header {
    height: 0;
  }
  .ico-group {
    padding-right: 10px;
  }
}
