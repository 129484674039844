.title-type10, .title-10 {
  text-decoration: none;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  display: block;
  font-family: Verdana, Arial, "Bitstream Vera Sans", sans-serif;
  color: #333 !important;
  margin: 60px 0 60px 0;
  border-bottom: 1px solid #d5d5d5;
  padding: 10px 20px 12px;
  display: inline-block;

  &.white {
    color: #ffffff;

    &:after {
      background: #ffffff;
    }
  }

  &.no-margin {
    margin: 0;
  }
}

.title-wrapper {
  display: block;
  text-align: center;
}
