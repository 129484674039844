body {
  label {
    font-weight: normal;
    margin-bottom: 8px;
  }

  .button-style {
    padding: 10px 30px;
  }

  .form-control {
    border-color: #e9e9e9;
    box-shadow: none;
  }
}

// list error
.info-error {
  color: #e2001b;

  .fa {
    margin-right: 5px;
  }
}

// style for error field
.has-error {
  .form-control {
    border-color: #e2001b;
  }
}

.red-color {
  color: #d10909;
}

.server-error {
  color: red;
  font-size: 12px;
  font-weight: bold;
  display: block;
  padding: 3px 0 3px 3px;
}