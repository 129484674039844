form.steps {
    box-shadow: 0 0 2px 3px #ccc;
    overflow: hidden;
    display: block;

    legend {
        margin-bottom: 10px;
    }

    .error {
        font-size: 0.8em;
        color: red;
        display: block;
    }

    .summary {
        display: table;
        width: 100%;
        margin-bottom: 20px;
        border-radius: 5px;
    }

    .summary {
        th {
            font-weight: bold;
        }

        td, th {
            padding: 5px;
        }
    }

    a.button {
        margin: 10px;
        padding: 5px;
        font-weight: bold;
    }

    a.button.prev {
        float: left;
    }

    a.button.next {
        float: right;
    }
    a.button.confirm {
        font-size: 1.5em;
        padding: 5px 50px;
        text-align: center;
    }

    >ul {
        position: relative;
        list-style: none;
        width: 100%;
        padding: 0;
        margin: 0 0 20px 0;
        height: 100px;

        >li.step {
            text-align: center;
            float: left;
            width: 25%;
            height: 100%;
            font-size: 20px;
            padding: 15px;
            margin: 0;
            position: relative;
            line-height: 0.8em
        }

        >li.step.clickable {
            cursor: pointer;
        }

        >li.step span {
            color: #333;
        }

        >li.current:after {
            content: "";
            position: absolute;
            top: 100%;
            left: 50%;
            border-left: 14px solid transparent;
            border-right: 14px solid transparent;
            margin-left: -14px;
        }

        >li .fa {
            font-size: 2em;
        }

        >li.current .fa,
        >li.current span {
            color: white;
        }
    }

    >fieldset,
    .notice {
        width: 100%;
        position: relative;
        float: left;
        clear: both;
        padding: 20px;
    }

    >fieldset {
        .reservation-grid-items{
            max-width: 550px;
            margin:0 auto;
            display: flex;
            display: -webkit-flex;
            display: -moz-flex;
            justify-content: space-between;
            -webkit-justify-content: space-between;
            -moz-justify-content: space-between;
            flex-flow: row wrap;
            -webkit-flex-flow: row wrap;
            -moz-flex-flow: row wrap;
        }
        .selectable {
            display: block;
            width: 80px;
            height: 80px;
            margin: 0px 3px 10px 3px;
            float: left;
            line-height: 80px;
            text-align: center;
            color: #333;
            border: solid 1px #ccc;
            border-radius: 5px;
            font-size: 1.5em;
        }
        .reservation-flex-dummy{
            width: 80px;
            height: 0px;
            margin: 0px 3px;
            padding-top: 0;
            padding-bottom: 0;
        }
    
        select.selectable {
            padding-left: 10px;
        }

        .selectable.weekend {
            //background-color: #fee;
        }

        .selectable.hour {
            width: 80px;
            height: 80px;
            line-height: 80px;
            font-size: 1.5em;
        }

        dt {
            float: left;
            clear: left;
            width: 200px;
            height: 23px;
        }

        dd {
            margin-bottom: 15px;
        }

        input,
        textarea {
            background-color: #f3f3f3;
            border: 1px solid #e4e4e4;
            clear: right;
        }
        .subscribe-label-offset{
            padding-left:30px;
            pointer-events:none;
            width:100%;
        }
        .checkbox-wrapper {
            position:relative;
            label {
                position: absolute;
                top: 0;
                left: 0;
                width: 20px;
                height:20px;
                cursor: pointer;
                color: inherit;
                background: #f3f3f3;
                border: 1px solid #e4e4e4;
                box-shadow: none;
                &:after {
                  content: '';
                  position: absolute;
                  top: 6px;
                  left: 5px;
                  width: 9px;
                  height: 5px;
                  color: #000;
                  border: 3px solid #000;
                  border-top: none;
                  border-right: none;
                  background: transparent;
                  opacity: 0;
                  transform: rotate(-45deg);
                }
            }
            input[type=checkbox] {
                visibility: hidden;
                &:checked + label:after {
                  opacity: 1;
                }
            }
        }

        textarea {
            width: 100%;
        }

        .calendar {
            border: 0;
            margin: 0 auto;

            thead {
                height: 3em;
            }

            th {
                vertical-align: top;
                font-weight: normal;
                font-size: 0.8em
            }

            * {
                text-align: center;
                text-align: center;
            }

            span {
                color: #ddd;
            }

            .today {
                color: #fee;
                border-radius: 0;
            }
            .today span, .today a {
                color: #aaa;
            }

            td {
                width: 40px;
                height: 40px;
                line-height: 40px;
                color: #aaa;
                cursor: not-allowed;
            }

            .selectable {
                font-size: 1em;
                border: 1px solid #333;
                display: table-cell;
                margin: 0;
                float: none;
                width: 40px;
                height: 40px;
                line-height: 40px;
            }

            .selectable.selected {
                border-width: 1px;
                border-radius: 0;
            }

            a {
                color: #333;
                display: block;
                //font-weight: bold;
            }

            .selected a, .today {
                color: white;
            }
        }
    }

    .arrow-down {
        width: 0;
        height: 0;
        border-left: 20px solid transparent;
        border-right: 20px solid transparent;
        border-top: 20px solid #f00;
    }
}

#reservation-modal {
    z-index: 10001;
}

.modal-backdrop {
    z-index: 10000;
}
