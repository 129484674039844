// slider
#slider {
    padding: 0px 0px;
}

#carousel-home {
    height: 500px;

    margin: 25px auto auto;
    padding: 0px;
    z-index: 100;

    &.large {
        margin-top: 0px;
        width: 100%;
    }

    &.full {
        margin-top: 0px;
        width: 100%;
        position: absolute;
        top: 0px;
    }

    &.tight {
        .item {
            text-align: center;
            img {
                display: inline-block;
                max-width: 100%;
                width: auto !important;
            }
        }
    }

    @media (max-width: $screen-sm-max) {
        margin-top: 0px;
    }
}

#mobile-home {
    h1, h3, h4 {
        color: $color_green;
    }
    p {
        color: $color_dark_grey;
    }
    // paragraph-1
    .paragraph-1 {
        .header-1 {
            background: url("../../imgs/common/ico-cluttery.png") no-repeat;
            display: inline-block;
            margin-bottom: 10px;
            padding-left: 30px;
        }
        p {
            font-size: 16px;
            line-height: 24px;
            margin-bottom: 30px;
            padding: 0 20px;
            text-align: justify;
        }
    }
    .button-paragraph-1 {
        background-color: #7c8182;
        border-radius: 5px;
        margin-bottom: 30px;
    }
    .paragraph-1-footer {
        color: $color_dark_grey;
        margin-bottom: 50px;
    }

    // news
    .mobile-news-bg {
        background-color: #eeeeee;
        background-image: url("../imgs/home/background-2.png");
        padding: 0 20px 40px;
        .news-item {
            background-color: #fff;
            box-shadow: 2px 4px 3px lighten($color_dark_grey, 30%);
            padding: 20px;
            h5 {
                color: $color_red;
                font-weight: 700;
                margin: 1em 0;
            }
            .news-item-description {
                padding: 0 20px;
            }
            p {
                text-align: justify;
            }
            a {
                color: $color_red;
                display: block;
                font-weight: 700;
                text-align: center;
            }
        }
    }
    .mobile-rating-bg {
        /*background-color: $color_red;*/
        /*background-image: url("../../imgs/home/pattern.png");*/
        padding-bottom: 30px;

        .reviews-img {
            min-height: 100%;
            min-width: 100%;

            max-height: none!important;
            max-width: none!important;
        }

        .header-2 {
            background: url("../../imgs/common/ico-quote.png") no-repeat;
            color: #fff;
            display: inline-block;
            margin-bottom: 10px;
            padding-left: 40px;
            &:after {
                background-image: url("../../imgs/common/line-pink.png");
            }
        }
        p {
            color: #fff;
            line-height: 2em;
            span {
                color: rgba(255,255,255, 0.6);
                font-weight: 700;
            }
            img {
                margin-top: -5px;
            }
        }
        i {
            color: #fff;
            font-style: normal;
            font-size: 20px;
        }
        a {
            text-decoration: none;
        }
    }
    .newsletter {
        padding: 30px 20px;
        header {
            margin-bottom: 20px;
            h4 {
                line-height: 1.5em;
            }
        }
        input {
            background-color: $color_grey;
            border-radius: 0;
            box-shadow: 1px 1px 2px lighten($color_dark_grey, 30%);
            border: 0;
            outline: 0;
            padding: 25px 15px;
            margin-bottom: 20px;
        }
        button {
            border-radius: 5px;
        }
    }
}

#main-header-left {
    .navbar-top-content {
        background-color: #171818;
        padding: 30px 20px;
        a {
            color: #fff;
            text-decoration: none;
        }
        .ferme {
            background-color: lighten(#171818, 20%);
            color: #fff;
            padding: 15px 5px;
        }
    }
    .navbar-menu-style {
        background-color: #f9f9f9;
        padding-bottom: 30px;
        .img-logo {
            display: inline-block;
        }
        ul.nav {
            li {
                display: block;
                width: 100%;
                text-align: center;
                a {
                    color: #353736;
                    font-weight: bold;
                    text-decoration: none;
                    &:hover {
                        color: #e3001b;
                    }
                }
            }
            .commander-reserve {
                padding: 10px 25px;
                a {
                    background-color: #e3001b;
                    color: #fff !important;
                    &:hover {
                        background-color: darken(#e3001b, 5%);
                    }
                }
            }
            .dropdown-menu {
                border-radius: 0;
                top: 6px;
                left: 75%;
            }
        }
    }
}
.newsletter-background {
    background-image: url("../../imgs/home/newsletter-bg-2.jpg");
    background-repeat: no-repeat;
    background-position: center;
}
#homepage-left  {
    .review-body {
        text-align: center;
        padding: 20px;
    }
    .new-list {
        padding: 0 20px;
    }
}

// slider
#carousel-home {
    height: auto;
    .item {
        img {
            width: 100%;
        }
    }
    .carousel-caption {
        background-color: rgba(0,0,0,.6);
        left: 0;
        margin-bottom: -20px;
        width: 100%;
        p {
            color: #fff;
            line-height: 1.5em;
            padding: 0 20px;
        }
    }
}

#main-header .menu-style {
    padding-top: 0 !important;
}

.navigation-wrapper {

    display: inline-table;

    #main-navbar {
        margin-bottom: 0;
        padding: 0px;
        height: auto;

        display: inline-table;

        #navbar-collapse-1 {
            line-height: 60px;
            width: 101%;
            z-index: 1000;
        }
    }

    @media (max-width: 767px) {
        padding: 0px !important;

        #main-navbar {
            height: auto; /*this need to be equal to the orange mobile buttons (phone and RESERVER)*/

            #navbar-collapse-1 {
                margin: 0px;
                padding: 0px;
                border: 0px;

                #nav-triangle-wrapper {
                    height: 10px;

                    .arrow-up {
                        z-index: 1000;
                        position: relative;
                        margin-left: 22px;

                        width: 0px;
                        height: 0px;

                        border-left: 10px solid transparent;
                        border-right: 10px solid transparent;
                    }
                }

                ul {
                    width: 100%;
                    border: 0px;
                    padding: 10px 0px;
                    margin: 0px;

                    li {
                        width: 100%;
                        border-bottom: 1px solid gray;
                        margin: 0px;
                    }
                }
            }
        }
    }
}

.home-review-title {
    width: 98% !important;
    margin-bottom: 0px !important;
}


@media (max-width: 992px) {
    .reviews-carousel {
        
    }
    .carousel-inner {
        //margin-top: 120px !important;
    }
    .carousel-control-wrapper {
        position: absolute;
        bottom: 0px !important;
    }
}

#news .new-text {
    height: 75px;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 24px;
}

.about-us {
    img {
        max-width: 100%;
    }
    .desc-image-left {
        float: left;
        margin-right: 20px;
    }
    .desc-image-right {
        float: right;
        margin-left: 20px;
    }
}
.closing-message {
    .alert {
        margin-bottom: 0 !important;

        .close {
            color: #fff !important;
            opacity: 1 !important;
            margin-top: -5px;
            i {
                font-size: 18px !important;
                text-shadow: none !important;
            }
        }
    }
}

#main-header {
    .navbar-top-content {
        line-height: 0px !important;
    }
    .clock-info {
        display: inline-block;
        margin-top: 0px;
        padding: 3px 20px 0 20px !important;
    }
    .ico-group-options {
        margin-top: -1px;
    }
    .ico-group-social {
        margin-top: 18px;
        a {
            display: inline-block;
            margin-top: -17px;
        }
    }
}

#restaurant-closed-modal {
    .close {
        i {
            font-size: 16px;
        }
    }
}

@media (max-width: 768px) {
    .about-us {
        img {
            max-width: 100%;
        }
    }
}

@media only screen and (max-width : 979px) {
    #reviews-carousel {
        .carousel-inner {
            margin-top: 0px;
        }
    }
}

@media only screen and (max-width : 600px) {
    .clock-info {
        padding: 0 !important;
    }
}

.triangled {
    #main-logo {
        height: 120px;
        position: absolute;
        top: -40px;
        z-index: 70;

        &.sticky {
            height: 50px;
            position: relative;
            top: 0px;

        }
    }

    .pull-right #main-logo {
        right: 0px;
    }


}

#newsletter-container {
    position: relative;

    #newsletter-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 1001;
    }
}