
[class*="bg-food"] {
  display: block;

  [class*="text"] {
    color: #fff;
    white-space: nowrap;
    display: block;

    [class*="text"] {
      display: initial;
    }
  }

  .text-small {
    font-size: 22px;;
  }

  .text-large {
    font-size: 24px;;
  }

}

.bg-food-1 {
  background: #bd6546;
  background-size: cover;
  min-height: 100px;
  position: relative;
  text-align: center;
  padding: 42px 0;

  &:before {
    content: '';
    background: url($path-to-img + "bg-food/bg-food-1.png") center;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }

  &:after {
    content: '';
    border: 3px solid #d07c5f;

    position: absolute;
    top: 6px;
    left: 6px;
    right: 6px;
    bottom: 6px;

  }
}