
// display inline block
.display-inline-block {
  display: inline-block;
  float: none;

  .middle {
    vertical-align: middle;
  }
}

.display-none {
  display: none;
}