#basket-sticky {
	position: fixed;
	min-height:300px;
	-webkit-transition: top 1s;
	-moz-transition: top 1s;
	-o-transition: top 1s;
	top: 200px;
    width: 400px;
    right: 10px;
    border: 2px solid #f5f5f5;
    padding: 0;
    z-index: 100;
	@media (max-width: 1799px) {
		height: 60%;
        right: -400px;
        -webkit-transition: right 0.5s;
        -moz-transition: right 0.5s;
        -o-transition: right 0.5s;
	}
}
#basket-title {
	padding: 10px;
	background-color: #e2001b;
	color:white;
}
#basket-body {
    background:white;
    overflow-y: auto;
    overflow-x: hidden;
}
#basket-products, #basket-total {
	margin-left:0;
    margin-right:0;
    background:white;
    border-top: 2px solid #f5f5f5;
}
#basket-products div.product-row {
	border-bottom:1px solid #f0f0f0;
}
#basket-products div.product-row:last-child {
  border-bottom:none;
}
.product-row {
	padding: 20px 20px 10px 5px;
}
.fa.fa-remove, .fa.fa-plus {
	color: #000;
}
.fa.fa-remove:hover, .fa.fa-remove:active, .fa.fa-plus:hover, .fa.fa-plus:active {
	background-color: red;
	color:white;
	cursor: pointer;
}
.basket-buttons div{
	margin-bottom: 10px;
	padding:0;
}
.basket-buttons div .fa {
	padding:5%;
		font-size:14px;
}
#basket-body div.product-name {
	font-weight: bold;
}
#basket-body div.product-description {
	padding-top:5px;
	padding-bottom: 10px;
}
.fa.fa-eur {
	font-size:14px;
}
#finalize-order {
	border-radius:0;
	padding:10px;
}
#slide-basket {
    font-size: 25px;
    padding: 11px;
    background-color: #e2001b;
    position: absolute;
    left: -50px;
    top: 0;
    cursor:pointer;
    color:white;

	@media (min-width: 1800px) {
		display:none;
	}
}
#basket-sticky.slide-to-left {
  right:0;
}

.no-minimum-amount {
	text-align: center;
	padding: 0 20px 20px 20px;
	font-weight: bold;
}


@media screen and (max-height: 600px){
	#basket-sticky {
		top: 60px;
	}
}