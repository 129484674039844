#categories-toogle-button {
    width: 100%;
    background-color: #ec7910;
    color: white;
    font-weight: bold;
    border-radius: 0px;
    margin-bottom: 0px;
    line-height: 34px;

    margin-top: 0px;
    margin-bottom: 0px;
    padding-left: 26px;
    padding-right: 26px;

    .container {
        width: 100%;
        margin: auto;

        span {
            font-size: 34px;
            font-weight: 100;
        }
    }
}

.sidebar-item {
    border-top: 2px solid #efefef;
    padding: 15px 0px;
    &:first-child {
        border-top: none;
    }
}

#sub-navigation {
    overflow: auto;

    .category-nav {
    font-size: 0;
    padding: 15px 0px;
    margin: 0;

    hr {
        display: none;
    }

    @media (max-width: $screen-sm-min) {
        background-color: white;
        color: gray;
        overflow-y: scroll;

        width: 100%;
        margin: auto;
        margin-bottom: 20px;

        hr {
            display: block;
        }
    }

    hr {
        width: 80%;
        margin: auto;
        border-width: 2px;
    }

    .cat-x {
        display: inline-block;
        border: 1px solid gray  !important;
        margin-right: 15px;
        cursor: pointer;
        margin-bottom: 5px;
        background-color: white !important;
        color: gray !important;

        padding: 5px 10px;

        &:last-child {
            margin-right: 0;
        }

        a {
            color: #a5a5a5;
            font-weight: bold;
            font-size: 14px;
            padding: 15px 25px;
            background: transparent;
            display: block;
        }

        &.marked-nav {
            background-color: gray !important;
            color: white !important;
        }

        &.active,
        &:hover {
            background: #171818;

            a {
                color: #ffffff;
            }
        }

        @media (max-width: $screen-sm-min) {
            width: 80%;
            margin: auto;
            display: block;

            border: 0px !important;
            line-height: 3em;

        }

    }
}
}
// list of meals version
.list-version {

    * {
        font-size: 16px;
    }

    p {
        color: #7f7f7f;
        margin-bottom: 0;
    }

    .list-version-title {
        color: #e31129;
        font-size: 22px;
    }

    .list-version-content {
        $list-version-color: #f5f5f5;

        background: $list-version-color;
        padding: 0 (35px + $grid-gutter-width / 2);

        .list-version-header {
            color: #7f7f7f;
            font-weight: bold;
        }

        .meal-category-name {
            display: inline-block;
        }

        .list-version-row {
            //margin: 0 $grid-gutter-width / 2;
            color: #878787;
            padding-top: 15px;
            padding-bottom: 30px;
            display: block;
            height: 105px;
            white-space: nowrap;
            overflow-x: hidden;
            overflow-y: hidden;
            border-top: 2px solid #efefef;
            font-size: 14px;
            line-height: 26px;
            position: relative;

            &:first-child {
                border-top: none;
            }            
            .list-version-image {
                position: absolute;
                top: 19px;
                left: 0;
            }
            .list-version-wrapper{
                display: table;
                width: 100%;
                &.meal-has-image{
                    margin-left: 100px;
                    width: calc(100% - 100px);
                }
                .list-version-name {
                    font-weight: bold;
                    white-space: initial;
                    overflow:hidden;
                    display: table-cell;
                    vertical-align: bottom;
                }
                .list-version-prices{
                    display: table-cell;
                    vertical-align: bottom;
                    background: $list-version-color;
                    padding: 0 5px;
                    width: 1%;
                    text-align: right;
                    .list-version-price  {
                        color: #ec7910;
                        font-weight: bold;
                        margin-left: 15px;
                        position: relative;
                        /* font-style: italic; */
                        display: inline-block;
                    }
                }
                .meal-has-image {
                    padding-left: 0px;
                }
                .meal-has-price{
                    background: #f5f5f5 url("/imgs/common/dot.gif") repeat-x bottom;
                    background-position: left 0px bottom 8px;
                    span {
                        background: #f5f5f5;
                        color: #878787;
                        padding-right: 5px;
                    }
                }
            }
            .list-version-desc {
                line-height: 26px;
                white-space: initial;
                margin-top: 5px;
                padding: 0px;
                &.meal-has-image{
                    margin-left:100px;
                }
            }
        }
    }
}

.gauche {
    background-color: #F5F5F5;
    padding: 25px;

    a {
        color: #878787;
        font-size: 16px;
        font-weight: bold;
    }
}

.list-version-content {
    ul {
        margin-bottom: 0;
    }
}

@media (min-width: 991px) {
    #menu-sticky {
        margin-top: 64px;

        .collapse {
            padding-top: 5px;
        }
    }

    .mCSB_outside + .mCSB_scrollTools {
        right: 2px !important;
    }

    #categories-navigation {
        overflow: hidden !important;
    }

}

@media (min-width: $screen-sm-min) and (max-width: 991px) {
    .mCSB_outside + .mCSB_scrollTools {
        right: 2px !important;
    }
}


.list-label {
    position: absolute;
    display: inline-block;
    left: 0;
    color: #7f7f7f;
    white-space: pre-line;
    text-align: right;
}

table.list-content-table {
    width: 100%;

    thead {
        &:before, &:after {
            content: "-";
            color: transparent;
            display: block;
            line-height: 30px;
        }

        tr th {
            color: #7f7f7f;
            text-align: right;
        }
    }

    tbody {

        tr.list-meal-row-content {
            line-height: 20px;

            td {
                font-weight: 700;

                div.meal-has-image {
                    margin-left: 100px;
                    padding-left: 0px;
                }
            }

            td.list-meal-row-name {
                > div.meal-has-price{
                    background: url("/imgs/common/dot.gif") repeat-x bottom;
                    background-position: left 0px bottom 3px;
                }               
                span {
                    background: #f5f5f5;
                    color: #878787;
                    padding-right: 5px;
                }
            }

            td.list-meal-row-price {
                background: #f5f5f5;
                font-style: italic;
                text-align: right;
                width: 95px;
                padding: 0 10px;
                vertical-align: bottom;
            }
        }

        tr.list-meal-row-description {
           // display: block;
            margin: 10px 0 30px;

            &:last-child {
                margin-bottom: 0;
            }

            td {
                color: #7f7f7f;
                padding: 5px 2px 30px 0px;
            }

            td.meal-has-image-description {
                padding-left: 100px;
            }
        }

        tr {
            td.list-meal-row-image-container {
                position: absolute;
                padding-right: 15px;
                background-color: #f5f5f5;
            }

            td .add-to-basket{
                padding:3px 10px;
                font-size:12px;
                color:white;
                border-radius: 5px;
            }
        }
    }
}

@media (max-width: 420px) {
    table.list-content-table tbody tr.list-meal-row-content td span.meal-has-image {
        padding-bottom: 30px;
    }

    table.list-content-table td {
        display: inline-block;
    }

    .list-version .list-version-content {
        padding: 0 5px;
    }
    td.list-meal-row-price-after-image {
        margin-top: 20px !important;
    }
}

[class*="go-back"] {
  cursor: pointer;
}
#cart-delivery-type-info {
  align-items: center;
  display: flex;
  justify-content: space-between;
}