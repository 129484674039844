.menu-1 {

  &.menu-item {
    position: relative;
    background: #f5f5f5;
    padding: 20px;

    .menu-img {
      // max-height: 150px;
      width: 100%;
      height: auto;
      max-height: none;
      box-shadow: 0 0 5px #eeeeee;
    }

    .menu-title {
      font-size: 24px;
      color: #e3001b;
      font-weight: bold;
      margin: 0 0 14px 0;
    }

    .menu-list {
      list-style: none;
      padding: 0;

      li {
        font-weight: bold;
        line-height: 24px;
      }

    }

    .menu-text {
      color: #7e7e7e;
    }


    .menu-price-wrapper {
      margin-bottom: -10px;
      position: absolute;
      left: 0;
      width: 100%;
      bottom: 0;
      padding: 0 20px;

      @media (max-width: 768px) {
        text-align: center;
      }

      .menu-price {
        color: #ffffff;
        background: #009136;
        font-weight: bold;
        border-radius: 5px;
        display: inline-block;
        position: relative;

        font-size: 32px;
        padding: 5px 40px;

        &:after {
          content: '';
          width: 29px;
          height: 15px;
          position: absolute;
          right: -21px;
          bottom: 6px;
          background: #006423;
          z-index: -1;

          -ms-transform: rotate(-27deg);
          -webkit-transform: rotate(-27deg);
          transform: rotate(-27deg);
        }
      }
    }

    @media (max-width: 768px) {
      .menu-right + .menu-right {
        margin:20px 0;
      }
    }

  }

}