@import "title-style";
@import "title-1";
@import "title-2";
@import "title-3";
@import "title-4";
@import "title-5";
@import "title-6";
@import "title-7";
@import "title-8";
@import "title-9";
@import "title-10";

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}