
.title-type6, .title-6 {
  position: relative;
  text-decoration: none;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  display: block;
  font-family: Verdana, Arial, "Bitstream Vera Sans", sans-serif;
  margin: 30px 0;
  color: #3c3e3e !important;
  text-transform: uppercase;
  overflow: hidden;
  clear: both;


  &.white {
    color: #ffffff;

    &:after {
      background: #ffffff;
    }

    &:before {
      background: #ffffff;
    }
  }

  &.no-margin {
    margin: 0;
  }

  .ico{
    background-image: url($path-to-img  + "fork-red.png");
    background-repeat: no-repeat;
    background-position: center;
    display: block;
    height: 32px;
    width: 100%;
    float: left;
    margin-top: 20px;

    &:before,
    &:after {
      content: '';
      background-image: url($path-to-img  + "line-segment.png");
      display: block;
      height: 5px;
      margin-bottom: 6px;
      width: calc(50% - 28px);
      float: left;
      margin-right: 12px;
      margin-top: 13px;
    }

    &:after {
      margin-right: 0;
      margin-left: 44px;
    }
  }


}
