#news-page {
  color: $color_dark_grey;

  .new {

    background: $color_grey;
    padding: 20px;
    overflow: hidden;

    @media (max-width: $screen-xs-max) {
      padding: 10px;
    }

    @media (max-width: $screen-xs-max) {
      &:nth-child(odd) {
        & > .row {
          &:before {
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 10px 0 10px 12px;
            border-color: transparent transparent transparent $color_grey;
            position: absolute;
            right: 8px;
            bottom: 100px;
          }
        }
      }

      &:nth-child(even) {
        & > .row {
          &:before {
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 10px 12px 10px 0;
            border-color: transparent $color_grey transparent transparent;
            position: absolute;
            left: 8px;
            bottom: 100px;
          }
        }
      }
    }
  }

  .title {
    color: $color_red;
    font-size: 20px;
  }

  .calendar {
    .fa {
      color: $color_green;
    }
  }

  .read-more {
    color: $color_red;
    font-size: 18px;
    line-height: 18px;
  }

  .img-responsive {
    min-width: 100%;
    height: auto;
    max-width: 100%;
  }
}

.news-content-left {
  height: 200px;
  text-align: center;

  img {
    height: 100%;
    margin: 0 auto;
  }
}
.news-content-right {
  height: 200px;
  position: relative;

  .new-read-more {
    float: right;
  }
}