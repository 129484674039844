.menu-3 {

  &.menu-item {
    position: relative;
    background: #f5f5f5;
    padding: 20px 20px 0 20px;

    .menu-img {
      // max-height: 150px;
      width: 100%;
      height: auto;
      max-height: none;
      box-shadow: 0 0 5px #eeeeee;
    }

    .menu-title {
      font-size: 24px;
      color: #e3001b;
      font-weight: bold;
      margin: 0 0 14px 0;
    }

    .menu-right {
      .menu-list {
        list-style: none;
        padding: 0;
        display: inline-block;

        li {
          font-weight: bold;
          line-height: 24px;
        }
      }
    }

    .menu-text {
      color: #7e7e7e;
    }

    .menu-price-wrapper {
      margin: -30px -10px 0 -10px;
      padding: 0 20px;
      display: block;

      &:after {
        content: '';
        display: block;
        height: 10px;
        background: #009136;
        z-index: 1;
        margin: 0 -20px;
      }

      @media (max-width: 768px) {
        text-align: center;
      }

      .menu-price {
        color: #ffffff;
        background: #009136;
        font-weight: bold;
        border-radius: 5px 5px 0 0;
        display: inline-block;
        position: relative;

        font-size: 32px;
        padding: 5px 40px;
        margin-bottom: -10px;
      }

    }

    @media (max-width: 768px) {
      .menu-right + .menu-right {
        margin:20px 0;
      }
    }

  }

}