.menu-8 {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 136px;


  .info {
    margin-top:30px;
    margin-bottom: 20px;
    font-weight: bold;
    color: #fff;
    font-size:18px;
  }
  .desc {
    font-size:18px;
  }

  &.green {
    background-image: url('/imgs/menu/pizza-holder-green.png');

    .desc {
      color: #79f2a6;
    }
  }

  &.red {
    background-image: url('/imgs/menu/pizza-holder-red.png');

    .desc {
      color: #fec5c5;
    }
  }

  &.black {
    background-image: url('/imgs/menu/pizza-holder-black.png');

    .desc {
      color: #abacac;
    }
  }
}