.reviews-carousel {
    min-height: 380px;

    .carousel-inner,
    .carousel-caption {
        height: 350px;
    }
    .item {
        max-height: 100%;
    }

    @media (max-width: $screen-sm-max) {
        background: #000000;
        height: auto;
        min-height: 100px;

        .reviews-img {
            /*display: none !important;*/
        }

        .carousel-inner,
        .carousel-caption {
            height: 350px;
            /* min-height: 100px;
            max-height: 100%; */
        }
    }

    .carousel-caption {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        max-height: 100%;

        &:before {
            content: '';
            display: inline-block;
            vertical-align: middle;
            width: 1px;
            margin-left: -1px;
            height: 100%;
        }
    }

    .carousel-caption-content {
        width: calc(100% - 5px);
        display: inline-block;
        vertical-align: middle;
    }

    .review-body {
        line-height: 24px;
        font-size: 14px;
        margin: 0 auto;

        max-height: 140px;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    .rating {
        unicode-bidi: bidi-override;
        direction: rtl;
        color: #979694;
        display: inline-block;

        i {
            padding: 3px 2px;

            &.selected,
            &:hover {
                color: #ffffff;

                ~ i {
                    color: #ffffff;
                }
            }
        }

    }

    .carousel-control-wrapper {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        text-align: center;

        .carousel-control {
            position: relative;
            background-image: none;
            float: none;
            display: inline-block;
            width: auto;

            &.left {
                margin-right: 20px;
            }
        }
    }

}