.text-green {
  color: #009136;
}

.text-grey {
  color: #464646;
}

a {
  text-decoration: none;

  &:focus,
  &:active,
  &:hover {
    text-decoration: none;
  }
}