.menu-2 {

  &.menu-item {
    position: relative;
    background: #f5f5f5;
    padding: 20px 20px 0 20px;

    .row {
      font-size: 0;
    }

    .menu-img {
      // max-height: 150px;
      width: 100%;
      height: auto;
      max-height: none;
      box-shadow: 0 0 5px #eeeeee;
    }

    .menu-title {
      font-size: 24px;
      color: #e3001b;
      font-weight: bold;
      margin: 0 0 14px 0;
    }

    .menu-right,
    .menu-left {
      display: inline-block;
      float: none;
      vertical-align: middle;
    }

    .menu-list {
      list-style: none;
      padding: 0;
      display: inline-block;
      margin: 0;

      @media (max-width: 768px) {
        text-align:center;
      }

      li {
        font-weight: bold;
        line-height: 24px;
      }
    }

    .menu-text {
      color: #7e7e7e;
    }

    .menu-price-wrapper {
      margin: 0 -10px;
      display: block;

      .menu-price {
        color: #ffffff;
        background: #009136;
        font-weight: bold;
        display: block;
        position: relative;
        font-size: 32px;
        padding: 5px 40px;
      }
    }

  }

}