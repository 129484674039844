$pag-height: $grid-gutter-width / 2;
$list: 0,1,2,3,4,5,6,7,8,9,10;
$media-end: 'test';

$media: (xs: (0, $screen-xs-max), sm: ($screen-sm-min, $screen-sm-max), md: ($screen-md-min, $screen-md-max), lg: ($screen-lg-min, 0) );

[class*="gap-"] {
    clear: both;
    display: block;
    float: none;
}

@each $j in $list {
    .gap-#{$pag-height * $j} {
        height: $pag-height * $j;
    } 
}

@each $name, $var in $media {

    @if nth($var, 1) != 0 { 
        $media-end: "(min-width: #{nth($var, 1)})";
    }

    @if nth($var, 2) != 0 { 
        $media-end: "(max-width: #{nth($var, 2)})";
    }

    @if nth($var, 2) != 0 and nth($var, 1) != 0 { 
        $media-end: "(min-width: #{nth($var, 1)}) and (max-width: #{nth($var, 2)})";
    }

    @media #{$media-end} {
        @each $j in $list {
            .gap-#{$name}-#{$pag-height * $j} {
                height: $pag-height * $j;
            }
        }
    }
}
