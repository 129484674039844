#legal-notice-page {
  margin: 40px auto 60px auto; 
  
  li {
    line-height: 160%;
    margin-bottom: 20px;
    
    em {
      display: block;
      font-style: normal;
      margin-bottom: 20px;
    }
     li {
       margin: 5px;
     }
  }
  
  p {
    line-height: 160%;
    margin-bottom: 20px;
  }
}