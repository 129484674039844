.breadcrumbs-menu {
  list-style-type: none;
  padding: 0;

  .item {
    display: inline-block;

    &:after {
      content: '\f105';
      margin: 0 10px;
      font-size: 16px;
      display: inline-block;
      font: normal normal normal 14px / 1 FontAwesome;
      font-size: inherit;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }

    &:last-child {
      &:after {
        content: ' ';
        display: none;
      }
    }
  }
}