.menu-4 {

  &.menu-item {
    position: relative;
    background: #f5f5f5;
    padding: 20px;

    .menu-img {
      // max-height: 150px;
      width: 100%;
      height: auto;
      max-height: none;
      box-shadow: 0 0 5px #eeeeee;
    }

    .menu-title {
      font-size: 24px;
      color: #e3001b;
      font-weight: bold;
      margin: 0 0 14px 0;
    }

    .menu-body {
      .menu-list {
        list-style: none;
        padding: 0;
        display: inline-block;

        li {
          font-weight: bold;
          line-height: 24px;
        }
      }
    }

    .menu-text {
      color: #7e7e7e;
    }

    .menu-price-wrapper {

      @media (max-width: 768px) {
        text-align: center;
      }

      .menu-price {
        color: #ffffff;
        background: #009136;
        font-weight: bold;
        border-radius: 25px;
        display: inline-block;
        position: relative;

        font-size: 32px;
        padding: 5px 40px;

      }

    }


  }

}