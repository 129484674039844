@import 'delivery-or-carryout';
@import 'where-do-you-live';
@import 'make-your-menu-lunch-menu';
@import 'ingredients';
@import 'side_dishes';

.title-price {
  display: inline-block;
  vertical-align: middle;
  border-radius: 50%;
  border: 5px solid #f9f9f9;
  width: 50px;
  height: 50px;
  line-height: 40px;
  font-size: 14px;
  color: #464646;
  text-align: center;

  position: relative;
  z-index: 1;

  &:before {
    z-index: -1;
    content: '';
    position: absolute;
    background: #f9f9f9;
    height: calc(100% - 4px);
    width: calc(100% - 4px);
    border-radius: 50%;
    left: 2px;
    top: 2px;

  }
}

.price-2 {
  color: #e2001b;
}

.modal {
  .modal-content {
    border-radius: 0;

    .separator {
      background: #f6f6f6;
      height: 2px;
      clear: both;
      display: block;
    }

    .modal-header {
      background: #009136;
      color: #fff;
      text-transform: uppercase;
      text-align: center;
      padding: 20px 0;
      position: relative;
      height: 60px;

      .close {
        color: #ffffff;
        opacity: 1;
        position: absolute;
        top: -30px;
        right: 0;

        .fa {
          font-size: 22px;
        }
      }
    }

    .modal-body {
      padding: 0 40px;
    }

  }
}

.modal-dialog {
  padding-top: 150px;
}
@media (max-width: $screen-xs-max) {
  .modal-dialog {
    padding-top: 50px;
  }
}
// modal widths
.modal-740 {
  .modal-dialog {
    width: 740px;
  }
}
.modal-500 {
  .modal-dialog {
    width: 500px;
  }
}

.modal-body button, 
.main-header, .modal-container button{
  text-transform: uppercase;
}

.main-header,{
  font-size: 16px;
  line-height: 1.7em;
}

.modal-body .help-text {
  color: #ee7114;
  font-size: 11px !important;
  font-weight: bold;
  display: none;
}