.map {
  //padding: 10px 50px 90px;
}

.map-address {
  list-style: none;
  margin: 30px 0 0 0;
  padding: 0;
  li {
    color: $color_dark_grey;
    margin-bottom: 8px;
    &:nth-child(2) {
      padding-left: 13px;
    }
    i {
      color: $color_red;
      font-style: normal;
    }
  }
}