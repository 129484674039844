.dish-4 {

  display: block;
  color: #464646;
  border: 1px solid #e9e9e9;
  line-height: 14px;
  padding: 15px 20px;
  cursor: pointer;
  margin-bottom: 15px;
  border-radius: 5px;
  position: relative;
  padding-right: 50px;

  .price {
    position: absolute;
    right: 0;
    top: 0;
    width: 50px;
    height: 100%;
    text-align: center;
    background: #e6e6e6;
    padding: 15px 0;
    border-radius: 0 5px 5px 0;
    display: block;
  }

  &:last-child {
    margin-bottom: 0;
  }

  [type="radio"] {
    display: none;
    opacity: 0;
  }

  &:hover,
  &.active {
    background: #464646;
    color: #fff;

    .price {
      color: #fff;
      background: #e2001b;
    }
  }

}