.modal.fade.make-your-menu-lunch-menu {

  z-index: 10130;

  // .delivery-modal-span {
  //   margin-top:15px;
  // }

  // .fa-question-circle {
  //   font-size: 22px;
  //   vertical-align: middle;
  //   margin-top: -4px;
  // }
  
  .modal-title {
    color:white !important;
    font-size:16px !important;
  }
}