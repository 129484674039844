.cookies {
  background: #E3E3E3;
  position: fixed;
  z-index: 10000;
  padding: 15px 0;
  text-align: center;
  font-family: Arial, Verdana, sans-serif;
  font-size: 12px;
  line-height: 180%;
  bottom: 0;
  width: 100%;
}
@media (max-width:499px){
  .cookies {
    display: none;
  }
}

.cookies .cookies-agree, .cookies .cookies-more {
  color: #fff;
  padding: 7px 5px;
  font-size: 13px;
  border-radius: 3px;
}

.cookies .cookies-agree {
  background: #EF6E06;
  margin: 0 5px;
}

.cookies .cookies-more {
  background: #2E3731;
}
.cookies-more:hover {
  color: #fff;
}

#cookie-page {
  margin: 40px auto 60px auto; 
  
  li {
    line-height: 160%;
    margin-bottom: 20px;
    
    em {
      display: block;
      font-style: normal;
      margin-bottom: 20px;      
    }
     li {
       margin: 5px;
     }
  }
}