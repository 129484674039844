.dish-2 {

  display: block;
  color: #464646;
  border: 1px solid #e9e9e9;
  line-height: 14px;
  padding: 15px 20px;
  cursor: pointer;
  margin-bottom: 15px;
  border-radius: 5px;

  &:last-child {
    margin-bottom: 0;
  }

  [type="radio"] {
    display: none;
    opacity: 0;
  }

  &.active {
    background: #464646;
    color: #fff;
  }

}