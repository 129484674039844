.comment {

  .name {
    color: $color_red;
    font-size: 20px;
  }

  .time {
    display: inline-block;
    border-right: 2px solid $color_grey;
    color: #000000;
    padding-right: 10px;
  }

  .reply {
    color: $color_red;
    display: inline-block;
    padding-left: 10px;
  }

}

.comment-form {

  label {
    color: $color_green;
    font-weight: bold;

    .require {
      color: $color_red;
      font-size: 20px;
    }

  }

  input, textarea {
    border: 2px solid $color_grey;
    width:100%;
    padding: 10px;
    background: #fff;
    height: 44px;

    @media(max-width: $screen-xs-max) {
      padding: 20px;
    }
  }

  textarea {
    height:150px;
  }

}