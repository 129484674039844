div#menu-nopadded {
        &.menu-position-middle {
            margin-left: 10px;

            #main-logo {
                margin-left: 15%
            }
        }
    ul {
        li {
            margin: 0 !important;

            &.active {
                a {
                    background-color: #000 !important;
                    border-radius: 0 !important;
                }
            }

            &#reserver-button {
                border: none !important;
                border-radius: 0 !important;
            }

            a:not(#reservation-button){
                background-color: #000 !important;

                &:hover {
                    border-radius: 0 !important;
                }
            }

            a, a#reservation-button {
                padding: 30px 20px;

                &:hover {
                    padding: 30px 20px !important;
                }
            }
        }
        @media(max-width: 768px) {
            li {
                a, a#reservation-button {
                    &:hover {
                        padding: 7px 10px !important;
                    }
                }
            }
        }
    }
}

div#menu-nopadded.fixed-styles {
    &.menu-position-middle {
        margin-left: 10px;

        #main-logo {
            position: absolute;
            top: 0;
            left: 33%;
        }
    }

    ul {
        margin-top: 7px;
        li {
            a, a#reservation-button {
                padding: 20px 20px 18px;

                &:hover {
                    padding: 20px 20px 19px !important;
                }
            }
        }
        @media(max-width: 768px) {
            li {
                a, a#reservation-button {
                    &:hover {
                        padding: 7px 10px !important;
                    }
                }
            }
        }
    }
}