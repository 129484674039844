footer {
  padding-top: 30px;
  
}
footer.site-footer {
  padding-bottom: 20px;
}
#footer {

  .footer-main {
    * {
      line-height: 30px;
    }
  }

  p {
    margin: 0;
  }

  color: #181818;

  a {
    color: #181818;
  }

  .footer-title {
    color: #181818;
    margin: 0 0 20px 0;

    &:after {
      margin-top: 10px;
      content: '';
      height: 2px;
      background: #ececec;
      font-size: 18px;
      width: 70px;
      display: block;
    }
  }

  .copyright {
    border-top: 2px solid #f2f2f2;

    p {
      color: #c9c9c9;
    }

  }
}

