#contact-v1 {

  //common header styles
  @for $index from 1 through 6 {
    h#{$index}{
      color: $color_green;
      font-weight: 500;
      text-align: center;
      text-transform: uppercase;
    }
  }

  .contact-info {
    margin-bottom: 60px;
  }

  .contact-map-address {
    background: rgba(255,255,255,0.9);
    position: absolute;
    width: auto;
    top: 31%;
    right: 45px;
    z-index: 99;

    .map-address {
      li {
        i {
          float: left;
          margin-right: 5px;
        }
      }
      li:nth-child(2) {
        padding-left: 16px;
      }
    }
  }

  .contact-us-form {
    form {

      label {
        font-weight: 700;
      }

      .contact-us-form-required-field {
        color: $color_red;
      }

      input, textarea {
        background-color: $color_grey;
        border-color: darken($color_grey, 6%);
      }

      .contact-us-form-send-btn {
        background-color: $color_red;
        border-color: darken($color_red, 10%);
        color: #fff;
        font-weight: 700;
        padding: 10px 20px;
      }

    }
  }

  .contact-us-schedule-list {
    color: black;
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      font-size: 14px;
      padding-bottom: 30px;
    }

    .contact-us-schedule-day {
      color: black;
    }

  }
}

#contact-captcha {
  img {
    width: 100%;
  }
}

.map-address {
  margin-bottom: 15px !important;
  padding: 0 20px;
}


.font-bold {
  font-weight: 700 !important;
}

#contact-button-wrapper {
  button {
    //position: relative;
    //bottom: -38px;
    float: right;
  }
}

.footer-contact-background {
  background-color: $color_contact_footer_bg;
}
.footer-contact {
  color: $color_contact_footer_text !important;
  padding: 20px;
  .font-red {
    color: $color_red;
  }
  .footer-contact-title {
    color: $color_contact_footer_text !important;
    display: inline-block;
    padding-bottom: 20px;
    &:after {
      color: $color_contact_footer_text;
      content: '- - - - - - - - - - -';
      font-weight: 700;
      display: block;
      width: 100%;
    }
  }
  ul a, span a, div a {
    color: $color_contact_footer_text !important;
  }
}

@media (max-width: 750px) {
  #contact-v1 {
    .pull-xs-left {
      text-align: left !important;
      margin: 0;
      padding-left: 20px;
    }
    .pull-xs-left-no-pad {
      text-align: left !important;
      margin: 0;
      padding-left: 3px;
    }
    .padding-xs-left {
      padding-left: 25px !important;
    }
    form {
      margin-bottom: 20px;
    }
    .contact-us-form {
      padding: 0 50px 0 !important;
    }
    .main-xs-header {
      text-align: left !important;
      margin-left: -20px;
      margin-bottom: 10px;
    }
    .contact-map-map-xs {
      padding: 0 30px !important;
    }
    .contact-map-address {
      width: 70%;
      position: absolute;
      top: 60%;
      left: 0;
      margin: 0 auto;
    }
    .contact-us-schedule {
      margin-left: -20px;
    }
    .copyright-contact-mobile {
      text-align: center;
      p {
        display: block !important;
        float: none !important;
      }
    }
  }
}

@media (max-width: 768px) {
  #contact-button-wrapper {
    text-align: center;
    button {
      position: relative;
      bottom: 0px;
      float: none;
    }
  }
}

#contact-v2 {
  //common header styles
  @for $index from 1 through 6 {
    h#{$index} {
      color: $color_green;
      font-weight: 500;
      margin-bottom: 20px;
      text-align: left;
      text-transform: uppercase;
    }
  }
  form {
    label {
      font-weight: 700;
    }
    .contact-us-form-required-field {
      color: $color_red;
    }
    input, textarea {
      background-color: $color_grey;
      border-color: darken($color_grey, 6%);
    }
    .contact-us-form-send-btn {
      background-color: $color_red;
      border-color: darken($color_red, 10%);
      color: #fff;
      font-weight: 700;
      padding: 10px 20px;
    }
    margin-bottom: 50px;
  }
  .contact-form {
    margin-top: 30px;
    h1 {

      text-align: center;
    }
  }
  .contact-map {
    padding-right: 20px;
  }
  .contact-schedule {
    ul {
      color: $color_dark_grey;
      list-style: none;
      padding: 0;
      margin: 0;
      li {
        font-size: 14px;
        margin-bottom: 15px;
      }
      .contact-us-schedule-day {
        color: $color_red;
      }
    }
  }
  .contact-find {
    ul {
      display: inline-block;
      list-style: none;
      margin-right: 10px;
      padding-left: 5px !important;
    }
    ul li {
      color: $color_dark_grey;
      margin-bottom: 8px;
      i {
        color: $color_red;
        font-style: normal;
      }
    }
    .contact-map-address-no-icon {
      padding-left: 13px;
    }
  }
}

@media (max-width: 750px) {
  #contact-v2 {
    .contact-map {
      padding: 0 !important;
      margin-bottom: 20px !important;
    }
    .contact-form {
      padding: 20px 50px;
      h1 {
        margin-left: -20px;
        margin-bottom: 10px;
        text-align: left;
      }
    }
    .contact-find, .contact-schedule {
      padding: 0 50px 20px;
      h3 {
        margin-left: -20px;
      }
    }
    .contact-schedule {
      ul {
        margin-left: -20px;
        margin-top: 10px !important;
        padding-left: 5px !important;
        padding-top: 0 !important;
      }
    }
    form {
      margin-bottom: 10px;
    }
    .contact-find {
      ul {
        margin-left: -20px;
        margin-top: 10px !important;
      }
    }
    .pull-xs-left {
      text-align: left !important;
      margin: 0;
      padding-left: 20px;
    }
    .pull-xs-left-no-pad {
      text-align: left !important;
      margin: 0;
      padding-left: 3px;
    }
    .padding-xs-left {
      padding-left: 25px !important;
    }
    .contact-us-form {
      padding: 0 50px 0 !important;
    }
    .main-xs-header {
      text-align: left !important;
      margin-left: -20px;
      margin-bottom: 10px;
    }
    .contact-map-map-xs {
      padding: 0 30px !important;
    }
    .contact-map-address {
      padding-left: 30px;
    }
  }
  .copyright-contact-mobile {
    text-align: center;
    p {
      display: block !important;
      float: none !important;
    }
  }
}