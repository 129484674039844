.title-type7, .title-7 {
  text-decoration: none;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  display: block;
  font-family: Verdana, Arial, "Bitstream Vera Sans", sans-serif;
  color: #333 !important;
  margin: 60px 0 60px 0;

  &:after {
    content: '';
    width: 70px;
    display: block;
    margin: 15px auto;
    height: 2px;
    background: #ececec;
  }

  &.white {
    color: #ffffff;

    &:after {
      background: #ffffff;
    }
  }

  &.no-margin {
    margin: 0;
  }
}
