
.cart-container {
  position: relative;
  height: 0;
  z-index: 99;

  .cart {
    $cart-width: 265px;

    * {
      font-size: 13px;
      line-height: 20px;
    }

    position: absolute;
    right: -$cart-width - 60px;
    top: 441px;

    width: $cart-width;

    // header
    .cart-header {
      position: relative;
      z-index: 2;

      margin: 0 -1px;
      display: block;
      box-shadow: 0 0 5px #efefef;

      background: #171818;
      padding: 0 30px;

      height: 46px;
      line-height: 46px;
      color: #ffffff;

      .text {
        vertical-align: middle;
        display: inline-block;
        margin-left: 5px;
      }

      .ico {
        vertical-align: middle;
        display: inline-block;
        height: 46px;
      }
    }

    // content
    .cart-body {
      position: relative;
      z-index: 1;

      padding: 0 35px;
      border-color: #ededed;
      border-width: 0 1px 1px 1px;
      border-style: solid;
      box-shadow: 0 0 5px #efefef;
    }

    // cart item
    .cart-item {

      border-bottom: 1px solid #f1f0f0;

      .cart-item-desc {
        margin: 0;
      }

      .cart-item-nav {
        .cart-quantity-button {
          width: 20px;
          height: 20px;
          line-height: 20px;

          display: inline-block;
          background: #e8e8e8;
          border: 0;
          padding: 0;
          text-align: center;
          outline: none;
          color: #515151;

          .fa {
            font-size: 10px;
            max-width: 100%;
            max-height: 100%;
          }

          &:hover,
          &:active {
            background: #e2001b;
            color: #ffffff;
          }
        }

        .cart-item-decrement {

        }

        .cart-item-increment {

        }
      }

    }

    // footer
    .cart-summary {
      .cart-summary-button-wrapper {
        margin: 0 -10px;

        .cart-summary-button {
          padding-left: 0;
          padding-right: 0;

          width: 100%;
          text-align: center;
        }
      }
    }

  }
}
