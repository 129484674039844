#main-header {
    box-shadow: 0px 0px 12px -10px;
    position: relative;
    z-index: 10000;

    #sub-navigation {
        margin-right: 0 !important;
    }

    .menu-style {
        margin-bottom: -2px;
        border-radius: 0px;
        position: relative;

        .container {
            position: relative;

            #reserver-button {
            }
        }

        li {
            margin-right: 1px;
        }

        &{
            border: 0;
        }

        @media (min-width: $screen-sm-min)  {

            padding-top: 20px;
            /*padding-bottom: 20px;*/
        }

        @media (max-width: $screen-sm-max)  {
/*            position: fixed;
            top: 0px;
            left: 0px;
            padding-top: 12px;
            background-color: white;
            z-index:1000;
            box-shadow: none;
            width: 100%;*/

            .container {
                padding: 0px;
                margin: 0px;
            }
        }
        @media (max-width: $screen-xs-max)  {

            .navebar-bg {
                background: #353736;
            }
        }
        /* page header nav */
        .main-nav {
/*            top: 20px;*/
            width: 100%;

            &{
                border: 0;
            }

            &.navbar-default {
                background-color: transparent;
                border: 0;
                max-height: 30px;

                .navbar-toggle {
                    border: 0;
                    margin-right: 0;
                    margin-left: 10px;
                    /*margin-top: 11px;*/

                    &:focus {
                        background-color:transparent;
                    }

                    .icon-bar {
                        background: transparent;
                        height: 0;
                        border-top: 2px solid #ffffff;
                        border-bottom: 2px solid #e3e3e3;
                        border-radius: 0px;
                    }
                }
            }

            /* top navebar on mobile */
            .navbar-top-mobile {
                 background-color: black; /*this should be replaced with dynamic
                color equals to the top-bar color*/

                width: 100%;
                margin-top: 0px;
                padding-bottom: -1px;

                .phone-info,
                .commander-reserve-mobile {
                    border-radius: 0px;
                    border: 0px;
                    width: 70px;
                    height:100%;
                    padding: 20px 5px;
                    font-size: 15px;
                    cursor: pointer;

                    i {
                        font-size: 20px;
                        margin-top: -20px;
                        margin-bottom: 10px;
                    }

                }


                .clock-info {
                    display: inline-block;
                    line-height: 54px;

                    height: 100%;
                    vertical-align: middle;
                    white-space: nowrap;

                    padding: 0;
                    margin: 0;
                    color: #fff;
                    vertical-align: baseline;
                    font-weight: normal;
                    border: 0px;

                    width: auto;
                    margin: auto;

                    .mobile-line-brake {
                        display: none;
                    }

                    @media (max-width: 450px) {
                        line-height: 20px;
                        margin-top: 10px;
                        font-size: 12px;
                        .mobile-line-brake {
                            display: block;
                            line-height: 0px;
                        }
                    }
                    @media (max-width: 360px) {
                        letter-spacing: -0.2px;
                        margin-left: -80px;
                        width: 75px;
                    }

                    .fa {
                        margin-right: 5px;
                    }
                }
            }

            .navbar-nav {

                font-size: 0;
                white-space: nowrap;
                width: 90%;
                height:80px;
                text-align:right;
                vertical-align:top;
                // margin-right: 3px;

                >li {
                    font-size: 14px;
                    display: inline-block;
                    vertical-align: middle;
                    float: none;

                    a {
                        text-align: center;
                        font-weight: normal;
                        color: #363837;
                    }

                    &.active {
                        a {
                            background: transparent;
                            color: #e3001b;
                        }
                    }

                    &.commander-reserve {
                        a {
                            padding: 10px 13px;
                        }
                    }

                }

            }
            @media (max-width: $screen-xs-max) {
                .navbar-collapse {
                    position: absolute;

                    .navbar-nav {
                        position: relative;
                        z-index: 1000;

                        >li {
                            display: block;
                        }
                    }
                }
            }

            .navbar-collapse {
                @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max)  {
                    text-align: center;

                    .navbar-nav {
                        display: inline-block;
                        float: none !important;
                        &.pull-right{
                            float: right !important;
                        }
                    }
                }

            }

        }

    }

    /* top navebar */
    .navbar-top {
        background: black;
        color: #fff;

        .navbar-top-content {
            /*height: 46px;*/
            line-height: 46px;
            margin:0px;
            padding: 0px;
            padding-bottom: -1px;
        }

        a,
        &.navbar-top-content {
            color: #ffffff;
            font-size: 17px;
        }

        .clock-info {
            padding: 9px 20px;
            background: transparent ;// rgba(255,255,255,0.2);
            vertical-align: middle;
            font-size: 17px;
        }

    }

    /* logo */
    .logo-mobile {
        text-align: center;
        a {
            display: inline-block;
            vertical-align: middle;
            float: none;
            img{
                height:100px;
                top:-20px;
                position:absolute;
                width:auto;
            }
        }
    }

}

#menu-style-flagged {
    // display: flex;
    // justify-content: space-between;

    &__logo {
        display: flex;
        justify-content: center;
        width: 100%;
        @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max)  {
            width: auto;
            margin-left: 30px;
            margin-right: 10px;
        }
    }

    ul {
        background-color: #2d2d2d;
        box-shadow: 0 0 2px 1px #fff;
        z-index: 2;
        height: 50px;
        li {
            background-color: #2d2d2d !important;
        }
    }
}

#menu-flag-left, #menu-flag-right {
    position: relative;
}

#menu-flag-left > i {
    position: absolute;
    display: inline-block;
    width: 80px;
    height: 50px;
    line-height: 0;
    border: 1.8em solid #2d2d2d;
    border-left: 1.5em solid transparent;
    left: -55px;
    top: -5px;
}

#menu-flag-right > i {
    position: absolute;
    display: inline-block;
    width: 80px;
    height: 50px;
    line-height: 0;
    border: 1.8em solid #2d2d2d;
    border-right: 1.5em solid transparent;
    right: -1020px;
    top: -5px;
}

.information-header-style {
    .phone {
        align-items: center;
        margin-top: 1px;
        .fa {
            margin-right: 5px;
            font-size: 20px;
            vertical-align: middle;
            line-height: 22px;
        }
    }
}

.flagged {
    .phone-info {
        margin-right: 10px;
    }
    .commander-reserve-mobile {
        margin-right: 5px;
    }
}

.triangled.menu-position-middle {
    .phone-info {
        margin-right: 20px;
    }
    .commander-reserve-mobile {
        margin-right: 5px;
    }
}

.menu-position-middle, .menu-position-right {
    .phone-info {
        margin-right: 10px;
    }
    .commander-reserve-mobile {
        margin-right: 5px;
    }
}
.menu-position-left {
    .phone-info {
        margin-right: 20px;
    }
    .commander-reserve-mobile {
        margin-right: 5px;
    }
}

.default-row-fixed {
    height: 60px;
    img {
        height: 60px !important;
        width: auto;
    }
    &.triangled img{
        top:0px !important;
        height: 80px !important;
    }
}

.nopadded-header, .flagged-header, .default-header {
    .phone-info,
    .commander-reserve-mobile {
        margin-top: 6px;
    }
}

.triangled-header {
    .navbar-toggle {
        margin-top: 6px !important;
    }
    .phone-info,
    .commander-reserve-mobile {
        margin-top: 0px;
    }

    .clock-info {
        @media (max-width: 420px) {
            margin-top: 6px !important;
        }
    }
}
