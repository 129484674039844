#todays-modal {
    .modal-dialog {
        padding-top: 10px;
    }

    .close {
        position: absolute;
        top: -30px;
        right: -4px;
    }

    .after-icon {
        padding-left: 10px;
    }

    .newsletter {
        align-items: center;
        color: #fff;
        display: flex;
        justify-content: space-between;
        padding: 20px;
        min-height: 30px;

        &__info {
            font-weight: 700;
        }

        input {
            width: 48%;
        }

        &__button {
            background-color: #fff;
            padding: 10px;
        }
    }

    #todays-modal-newsletter-button-wrapper {
        text-align: right !important;
        width: 100%;
    }
    #newsletter-todays-form {
        width: 100%;
        text-align: center;
        display: none;
        
        input {
            line-height: 36px;
            color: #000 !important;
            padding: 0 3px;
        }

        .newsletter__button {
            color: #fff !important;
        }
    }
    .title-wrapper .title-type8 {
        margin-top: 20px !important;
    }

    span#show-todays-newsletter-form {
        display: inline !important;
        cursor: pointer;        
    }

    .sub-header {
        margin-top: -45px;
        margin-bottom: 40px;
    }

    .section-wrapper {
        align-items: center;
        display: flex;
        margin-bottom: 40px;
        padding: 0 50px;

        .section-content-wrapper {
            width: 100%;
            text-align: center;

            h3 {
                margin-bottom: 20px;
            }
        }

        .section-image-wrapper {
            width: 40%;

            div {
                display: inline;
                text-align: left;

                img {
                    display: inline-block;
                    height: 100%;
                    width: 70%;
                }

                div {
                    display: inline-block;
                    font-weight: 700;
                    margin-top: 10px;
                }
            }

        }
    }
    .section-wrapper-main {
        flex-direction: column;

        .section-content-wrapper {
            width: 100%;

            text-align: center;
        }
        
        .section-image-wrapper {
            width: 100%;
            
            div {
                img {
                    display: block;
                    height: auto;
                    width: auto;
                    max-width: 100%;
                    margin: 20px auto 0 auto;
                }
            }
        }
    }

    .introduction {
        padding: 20px;
    }

    .meals {
        list-style: none;
        
        li {
            span {
                display: block;
            }
        }
        &__meal-name {
            font-weight: 700;
        }

        &__meal-text {
            color: grey;
            margin-bottom: 10px;
        }
    }
}