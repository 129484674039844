.dish-3-list {
  margin: 0 -17px;
  display: inline-block;
  overflow: hidden;

  .dish-3 {

    display: inline-block;
    border: 1px solid #e9e9e9;
    cursor: pointer;
    margin: 0 17px 57px 17px;
    height: 44px;
    width: 44px;
    position: relative;

    .label {
      position: absolute;
      top: 52px;
      display: block;
      width: 64px;
      left: -12px;
      padding: 0;
      margin: 0;
      color: #464646;
      font-size: 12px;
      text-align: center;
      white-space: inherit;
    }

    img {
      min-width: 100%;
      min-height:100%;
      max-width: 100%;
      max-height:100%;
    }

    [type="radio"] {
      display: none;
      opacity: 0;
    }

    &.active {
      border: 2px solid #e2001b;
    }

  }
}
