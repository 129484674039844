// buttons
.tab-button-1 {

  li {
    display: table;
    width: 100%;
  }

  .tab-button {
    background: #f0f0f0;
    text-align: center;
    height: 60px;
    line-height: 22px;
    font-weight: bold;
    color: #464646;
    margin-right: 20px;
    margin-bottom: 1px;
    display: table-cell;
    vertical-align: middle;

  }

  .disabled-text {
    display: none;
  }

  .disabled {
    .tab-button {
      color: #b3b3b3;

      .disabled-text {
        color: #b3b3b3;
        font-weight: normal;
        display: block;
      }
    }
  }

  .active {
    .tab-button {
      background: #e2001b;
      color: #ffffff;
      position: relative;

      &:after {
        content: '';
        width: 20px;
        height: 20px;
        background: #e2001b;
        position: absolute;
        top: 50%;
        margin-top: -10px;
        right: -10px;

        -ms-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg);
      }

      .disabled-text {
        display: none;
      }
    }
  }
}
